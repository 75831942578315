import {useMemo, useReducer} from "react";
import {AsyncCallbackResponse, omit, sequence, useAsyncCallback} from "../../utils";
import {Action} from "../../models";
import shortid from 'shortid';
import {LS} from "../../localstorage";
import {DoObject, DoObjectInput, DoObjectSerialiser, SerialisableDoObject} from "../../types/core";

const INDEX = `.index`;

export enum DataActions {
    Save,
    Remove,
}

export class LocalDB<T extends DoObject, U extends SerialisableDoObject> {
    private serialiser: DoObjectSerialiser<T, U>;

    constructor(serialiser: DoObjectSerialiser<T, U>) {
        this.serialiser = serialiser;
    }

    async has(id): Promise<boolean> {
        const index: string[] = await this._get(INDEX);
        const object: T = await this._get(id);
        return object && index.includes(id);
    }

    async save(object: T): Promise<T> {
        const index: string[] = (await this._get(INDEX)) || [];
        if (!index.includes(object.id)) {
            index.push(object.id);
            await this._set(INDEX, index);
        }

        await this._set(object.id, this.serialiser.preSave(object));
        return object;
    }

    async saveArray(objects: T[]): Promise<T[]> {
        await sequence(objects, object => this.save(object));
        return objects;
    }


    async remove(id: string): Promise<T> {
        const index: string[] = await this._get(INDEX);
        const current = await this._get(id);
        await this._set(INDEX, index.filter(i => i !== id));
        await this._delete(id);
        return current;
    }

    async load(): Promise<T[]> {
        const index: string[] = await this._get(INDEX);
        return index && (await Promise.all(index.map(i => this._get(i))))
            .filter(x => !!x)
            .map(o => this.serialiser.onLoad(o));
    }

    async _get(key: string) {
        const x = LS.getItem(`${this.serialiser.type}_${key}`);
        return x && JSON.parse(x);
    }

    async _set(key: string, value: any) {
        await LS.setItem(`${this.serialiser.type}_${key}`, JSON.stringify(value));
    }

    async _delete(key: string) {
        await LS.removeItem(`${this.serialiser.type}_${key}`);
    }
}

export function useLocalDBReducer<T extends DoObject, U extends SerialisableDoObject>(db: LocalDB<T, U>, initialObjects: {[id: string]: T}) {
    const [state, _update] = useReducer((objects: {[id: string]: T}, action: Action<T>) => {
        switch (action.type) {
            case DataActions.Save: {
                const {object} = action;
                return {
                    ...objects,
                    [object.id]: object
                }
            }
            case DataActions.Remove: {
                const {id} = action;
                return omit(objects, id);
            }
        }
        return objects;
    }, initialObjects);

    const update = useAsyncCallback<Action<T>, void>(async (action: Action<T>) => {
        switch (action.type) {
            case DataActions.Save: {
                const {object} = action;
                if (!object.id) {
                    object.id = shortid.generate();
                }
                await db.save(object);
            }
                break;
            case DataActions.Remove: {
                await db.remove(action.id);
            }
                break;
        }

        _update(action);
    }, [db, _update]);

    return useMemo(() => [state, update.execute, update], [state, update]);
}
