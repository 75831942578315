import shortid from 'shortid';
import {DoObject, DoObjectInput, DoObjectSerialiser, DoObjectType, SerialisableDoObject} from "./core";
import Case from "case";

export const OptionType = DoObjectType.Option;
export const OptionsLabel = 'Suggestions';
export const OptionLabel = 'Suggestion';
export const OptionsIcon = 'pi pi-fw pi-list';


export interface Option extends DoObject {
    categories: string[];
}

export interface OptionInput extends DoObjectInput {
    categories: string[];
}

export interface SerialisableOption extends SerialisableDoObject {
    categories?: string[];
    categoryLabels?: string[];
}

export function createOption(input: OptionInput): Option {
    const {id, label, categories} = input;
    return {
        type: OptionType,
        id: id || shortid.generate(),
        label,
        categories
    }
}

export class OptionSerialiser implements DoObjectSerialiser<Option, SerialisableOption> {
    type: string = OptionType;

    onLoad(input: SerialisableOption): Option {
        const {categories, categoryLabels, ...rest} = input;
        return createOption({
            ...rest,
            categories: categories || categoryLabels.map(c => Case.snake(c))
        });
    }

    preSave(object: Option): SerialisableOption {
        return {
            ...object,
            _version: 1
        };
    }

}
