import React, {useCallback, useContext} from 'react';
import classnames from 'classnames';
import {ModelContext, SpinContext, SpinnerSegment} from "../../models";
import styled from "styled-components";
import {RADIUS} from "../../../../utils";
import {SpinnerModelContext} from "../../containers/SpinnerModelProvider";
import {DoObject} from "../../../../types/core";

type BoardProps = {
    segments: SpinnerSegment[];
}
export function Wheel<T extends DoObject>({segments}: BoardProps) {
    return <svg height="100%" width="100%" viewBox={`0 0 100 100`} preserveAspectRatio="none">
        <g transform={`translate(50, 50)`}>
            <circle strokeWidth={2} r={49} cx={0} cy={0} style={{opacity: 0.7, stroke: 'var(--surface-d)'}}/>
            {segments?.map(segment => <Segment key={`s${segment.i}`} segment={segment} />)}
        </g>
    </svg>;
}

type SegmentProps = {
    segment: SpinnerSegment;
    className?: string
}
const Segment = styled(({segment, className, ...rest}: SegmentProps) => {
    const {actions} = useContext(SpinnerModelContext);
    const {deg} = useContext(ModelContext);
    const {spinning} = useContext(SpinContext);
    const {i, colour, selected, disabled} = segment;

    const toggleDisabled = useCallback(() => {
        actions.updateSegment(i, {...segment, disabled: !disabled});
    }, [disabled, actions.updateSegment, i, segment]);

    const opacity =  selected ? 1 : 0.4;
    const fill = disabled ? 'grey' : `rgb(${colour.join(',')})`;
    return <g {...rest} className={classnames(className, spinning && 'spinning')}>
        <path className='arc' d={describeArc(0, 0, i * deg, (i + 1) * deg, 48)} fill={fill} strokeWidth={0.1} stroke='white' style={{opacity}} onClick={toggleDisabled}/>
    </g>;
})`
  cursor: pointer;

  &.spinning .arc {
      animation-name: blinker;
      animation-iteration-count: infinite;
      animation-delay: ${({segment}) => segment.i * 0.05}s;
      animation-timing-function: linear;
      animation-duration: 0.5s;
  }
`;



function describeArc(x: number, y: number, startAngle: number, endAngle: number, radius: number = RADIUS) {
    const start = polarToCartesian(x, y, endAngle, radius);
    const end = polarToCartesian(x, y, startAngle, radius);
    const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
        "L", x, y,
        "L", start.x, start.y
    ].join(" ");

    return d;
}

function polarToCartesian(centerX: number, centerY: number, angleInDegrees: number, radius: number = RADIUS) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}
