import React, {useCallback, useState} from 'react';
import {useIsSmallScreen} from "../../../hooks";
import {ToolbarMenu} from "./ToolbarMenu";
import {SmallScreenMenu} from "./SmallScreenMenu";
import {OptionForm} from "../../data/components/OptionForm";
import {SpinnerForm} from "../../data/components/SpinnerForm";
import {Sidebar} from "primereact/sidebar";
import {CategoryForm} from "../../data/components/CategoryForm";
import {DoObjectType} from "../../../types/core";
import {CategoryLabel} from "../../../types/category";
import {OptionLabel} from "../../../types/option";
import {SpinnerModelLabel} from "../../../types/spinner";


export function Menu(props) {
    const smallScreen = useIsSmallScreen();
    const [formType, setFormType] = useState<DoObjectType>(null);

    let menu;
    if (smallScreen) {
        menu = <SmallScreenMenu {...props} showAddForm={setFormType}/>;
    } else {
        menu = <ToolbarMenu {...props} showAddForm={setFormType}/>
    }

    const onHide = useCallback(() => setFormType(null), [setFormType]);

    let form, header;
    switch (formType) {
        case DoObjectType.Category:
            header = CategoryLabel;
            form = <CategoryForm onSave={onHide} onCancel={onHide}/>;
            break;
        case DoObjectType.Option:
            header = OptionLabel;
            form = <OptionForm onSave={onHide} onCancel={onHide}/>;
            break;
        case DoObjectType.Spinner:
            header = SpinnerModelLabel;
            form = <SpinnerForm onSave={onHide} onCancel={onHide}/>;
            break;
    }

    return <React.Fragment>
        {menu}
        {form && <Sidebar visible={true} fullScreen onHide={onHide}>
          <h1>Add {header}</h1>
          {form}
        </Sidebar>}
    </React.Fragment>
}
