import React, {useCallback, useContext, useState} from 'react';
import {BlockForm} from "../../../components/BlockForm";
import {DataContext, DoObjectFormProps} from "../models";
import {DataActions} from "../localDB";
import {InputText} from "primereact/inputtext";
import {SaveOrCancel} from "../../../components/SaveOrCancel";
import {Button} from "primereact/button";
import {useSafeCallback} from "../../../utils";
import {Category, createCategory} from "../../../types/category";

type CategoryProps = DoObjectFormProps<Category>;

export function CategoryForm({object: category, onSave, onDelete, onCancel, ...props}: CategoryProps) {
    const {updateCategories} = useContext(DataContext);
    const [label, setLabel] = useState(category?.label || '');

    const updateCategory = useSafeCallback((e) => {
        const object = createCategory({
            ...(category || {}),
            id: category?.id,
            label
        });
        updateCategories({
            type: DataActions.Save,
            object
        });
        if (onSave) {
            onSave(object);
        }
    }, [label, setLabel, category, onSave]);

    const deleteCategory = useCallback(() => {
        if (category) {
            updateCategories({
                type: DataActions.Remove,
                id: category.id
            });
        }
        onDelete();
    }, [category, updateCategories, onDelete]);

    const htmlFor = category?.id || '__add';
    return <BlockForm onSubmit={updateCategory} {...props}>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label htmlFor={htmlFor} className="p-col-12 p-md-2">Label</label>
                <div className="p-col-12 p-md-10">
                    <InputText id={htmlFor} label='Label' autoFocus onChange={e => setLabel(e.currentTarget.value)} value={label}/>
                </div>
            </div>
        </div>
        <SaveOrCancel onSave={updateCategory} onCancel={onCancel}>
            {category && <div className="p-field">
              <Button label='Delete' icon="pi pi-times-circle" className="p-button-danger p-button-raised" onClick={deleteCategory}/>
            </div>}
        </SaveOrCancel>
    </BlockForm>;
}
