import {Colour} from "../../models";
import React from "react";
import {DoObject} from "../../types/core";
import {SpinnerModel} from "../../types/spinner";

export interface SpinnerSegment {
    object: DoObject;
    i: number;
    colour?: Colour;
    selected?: boolean;
    disabled?: boolean;
}

export interface SpinState {
    spinning?: boolean;
    choice?: Choice;
}

export interface Choice {
    degrees: number;
    i: number;
}






export const SpinContext = React.createContext<SpinState>(null);
export const ModelContext = React.createContext<SpinnerModel>(null);
