import React, {useContext} from 'react';
import {DataContext} from "../data/models";
import {Page} from "../../components/Page";
import {CategoryForm} from "../data/components/CategoryForm";
import {CategoriesLabel} from "../../types/category";
import {DoObjectList} from "../data/components/DoObjectList";

export function CategoriesPage() {
    const {categoryArray} = useContext(DataContext);
    return <Page>
        <h1>{CategoriesLabel}</h1>
        <DoObjectList array={categoryArray} Form={CategoryForm}/>
    </Page>;
}
