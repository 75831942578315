import {RoutePaths} from "../routes";
// @ts-ignore
import {navigate} from "hookrouter";

export interface IDObject {
    type: string;
    id: string;
}

export interface IDObjectInput {
    id?: string
}

export interface SerialisableIdObject {
    type: string
    _version?: number;
    id: string
}

export interface DoObject extends IDObject {
    label: string;
}

export interface DoObjectInput extends IDObjectInput {
    label: string;
}

export interface SerialisableDoObject extends SerialisableIdObject {
    label: string;
}

export interface DoObjectSerialiser<T extends DoObject, U extends SerialisableDoObject> {
    type: string;

    preSave(object: T): U;

    onLoad(input: U): T;
}

export enum DoObjectType {
    Category = 'Category',
    Option = 'Option',
    Spinner = 'SpinnerModel'
}


export const SettingsLabel = 'Settings';
export const SettingsIcon = 'pi pi-fw pi-cog';

export const FeedbackLabel = 'Feedback';
export const FeedbackIcon = 'pi pi-fw pi-comment';

// @ts-ignore
export const openFeedback = () => $sleek.open();
