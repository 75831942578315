export const PREFIX = `doSpinner`;


export const LS: Storage = {
    length: localStorage.length,
    setItem(k, v) {
        localStorage.setItem(`${PREFIX}_${k}`, v);
    },
    getItem(k) {
        return localStorage.getItem(`${PREFIX}_${k}`);
    },
    removeItem(k) {
        localStorage.removeItem(`${PREFIX}_${k}`);
    },
    clear() {
        localStorage.clear();
    },
    key(index) {
        return localStorage.key(index);
    }
};
