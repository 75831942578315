import {ModelContext, SpinnerSegment} from "../../models";
import React, {useCallback, useContext, useLayoutEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {useWindowSize} from "../../../../hooks";
import {RADIUS} from "../../../../utils";
import {SpinnerModelContext} from "../../containers/SpinnerModelProvider";

type SegmentContentProps = {
    segment: SpinnerSegment
}
export function SegmentContent({segment}: SegmentContentProps) {
    const {actions} = useContext(SpinnerModelContext);
    const {deg} = useContext(ModelContext);
    const {i, object: {label}, disabled} = segment;

    const toggleDisabled = useCallback(() => {
        actions.updateSegment(i, {...segment, disabled: !disabled});
    }, [disabled, actions.updateSegment, i, segment]);


    return <SegmentContentDiv i={i} deg={deg} disabled={disabled} onClick={toggleDisabled}>
        {label}
    </SegmentContentDiv>;
}

const SegmentContentDiv = styled(({style={}, ...props}) => {
    const ref = useRef(null);
    const [[width, height], setDims] = useState([0,0]);
    const windowSize = useWindowSize();
    useLayoutEffect(() => {
        const dims = ref.current.getBoundingClientRect();
        setDims([dims.width, dims.height]);
    }, [ref.current, windowSize]);

    style = useMemo(() => ({
        ...style,
        marginTop: `-${height/2}px`,
        marginLeft: `-${width/2}px`,
    }), [style, width, height]);

    return <div ref={ref} style={style} {...props} />;
})`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: ${RADIUS}vmin;
  // margin-left: -${RADIUS / 16}vmin;
  // margin-top: -${RADIUS / 32}vmin;
  // width: ${RADIUS / 12}vmin;
  //height: ${RADIUS / 8}vmin;
  cursor: pointer;
  text-align: center;
  font-size: ${RADIUS / 15}vmin;
  font-weight: bold;
  word-wrap: break-word;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  color: white;
  opacity: ${({disabled}) => disabled ? 0.3 : 1};
  transform:  
    rotate(${({i, deg}) => i * deg - 90 + deg / 2}deg)
    translate(${RADIUS / 1.4}vmin)
    rotate(${({i, deg}) => i * -deg + 90 - deg / 2}deg)
    ;  
`;
