import shortid from 'shortid';
import {DoObject, DoObjectInput, DoObjectSerialiser, DoObjectType, SerialisableDoObject} from "./core";

export const CategoryType = DoObjectType.Category;
export const CategoriesLabel = 'Categories';
export const CategoryLabel = 'Category';
export const CategoriesIcon = 'pi pi-fw pi-sitemap';


export interface Category extends DoObject {
}

export interface CategoryInput extends DoObjectInput {
}

export interface SerialisableCategory extends SerialisableDoObject {
}

export function createCategory(input: CategoryInput): Category {
    const {id, label} = input;
    return {
        type: CategoryType,
        id: id || shortid.generate(),
        label,
    }
}

export class CategorySerialiser implements DoObjectSerialiser<Category, SerialisableCategory> {
    type: string = CategoryType;

    onLoad(input: SerialisableCategory): Category {
        return createCategory(input);
    }

    preSave(object: Category): SerialisableCategory {
        return {
            ...object,
            _version: 1
        };
    }
}
