import shortid from 'shortid';
import {DoObject, DoObjectInput, DoObjectSerialiser, DoObjectType, SerialisableDoObject, SerialisableIdObject} from "./core";
import {SpinnerSegment} from "../modules/spinner/models";
import {Colours} from "../models";

export const SpinnerModelType = DoObjectType.Spinner;
export const SpinnerModelsLabel = 'Spinners';
export const SpinnerModelLabel = 'Spinner';
export const SpinnerModelsIcon = 'pi pi-fw pi-compass';

export interface SpinnerModel extends DoObject {
    segments: SpinnerSegment[];
    n: number;
    deg: number;
}

export interface SpinnerModelInput extends DoObjectInput {
    segments: DoObject[];
}

export interface SerialisableSpinnerModel extends SerialisableDoObject {
    segments: SerialisableIdObject[];
}

export function createSpinnerModel(input: SpinnerModelInput): SpinnerModel {
    const {id, label, segments} = input;
    return {
        type: SpinnerModelType,
        id: id || shortid.generate(),
        label,
        segments: segments.map((object, i) => ({
            i,
            object,
            colour: Colours.get(segments.length, i)
        })),
        n: segments.length,
        deg: 360 / segments.length
    }
}

export class SpinnerModelSerialiser implements DoObjectSerialiser<SpinnerModel, SerialisableSpinnerModel> {
    type: string = SpinnerModelType;

    onLoad(input: SerialisableSpinnerModel): SpinnerModel {
        return undefined;
    }

    preSave(object: SpinnerModel): SerialisableSpinnerModel {
        const {id, label, segments, type} = object;
        return {
            id, label, type,
            segments: segments.map(({object: {id, type}}) => ({id, type})),
            _version: 1
        };
    }

}
