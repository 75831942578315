import React, {useContext} from 'react';
import {Page} from "../../components/Page";
import {DataContext} from "../data/models";
import {DoObjectList} from "../data/components/DoObjectList";
import {OptionForm} from "../data/components/OptionForm";
import {OptionsLabel} from "../../types/option";


export function OptionsPage() {
    const {optionsArray} = useContext(DataContext);
    return <Page>
        <h1>{OptionsLabel}</h1>
        <DoObjectList array={optionsArray} Form={OptionForm} />
    </Page>;
}
