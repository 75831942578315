import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import styled from "styled-components";
import {DataContext, DataState} from "../models";
import {useLocalDBReducer} from "../localDB";
import {Category, SerialisableCategory} from "../../../types/category";
import {Option, SerialisableOption} from "../../../types/option";
import {initialise} from "../initialise";
import {CategoryDB, OptionDB} from "../dbs";
import {useAsyncCallback} from "../../../utils";
import {Messages} from "primereact/messages";



export function DataContainer({children}) {
    const load = useAsyncCallback(initialise, [initialise]);
    const reset = useCallback(() => {
        localStorage.clear();
        load.execute();
    }, [load]);

    useEffect(() => {
        // @ts-ignore
        load.execute();
    }, []);

    if (load.loading || !load.firstLoad) {
        return <React.Fragment/>;
    }

    if (!load.result) {
        return <FailedToLoad/>;
    }

    return <DataProvider initialData={load.result} reset={reset}>
        {children}
    </DataProvider>;
}

function DataProvider({children, initialData, reset}) {
    const [categories={}, updateCategories] = useLocalDBReducer<Category, SerialisableCategory>(CategoryDB, initialData.categories);
    const [options={}, updateOptions] = useLocalDBReducer<Option, SerialisableOption>(OptionDB, initialData.options);

    const dataState = useMemo(() => ({
        categories,
        categoryArray: Object.values(categories),
        options,
        optionsArray: Object.values(options),
        updateCategories,
        updateOptions,
        reset
    }), [categories, updateCategories, options, updateOptions, reset]) as DataState;


    return <DataContext.Provider value={dataState}>
        {children}
    </DataContext.Provider>;
}

function FailedToLoad() {
    const ref = useRef(null);
    useLayoutEffect(() => {
        ref.current.show([
            { severity: 'error', summary: 'Error', detail: 'Failed to load initial content', closable: false, sticky: true }
        ])
    }, []);
    return <ErrorPage>
        <Messages ref={ref} />
    </ErrorPage>
}

const ErrorPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

