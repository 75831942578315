import React, {ReactNode} from "react";
import {Button} from "primereact/button";
import classnames from 'classnames';


type Props = {
    onSave(e: any): void;
    onCancel?(e: any): void;
    className?: string;
    saveText?: string;
    cancelText?: string;
    children?: ReactNode;
}

export function SaveOrCancel({onSave, onCancel, saveText = 'Save', cancelText = 'Cancel', className, children, ...props}: Props) {
    return <div {...props} className={classnames('p-formgroup-inline p-mt-4', className)}>
        <div className="p-field">
            <Button label={saveText} icon="pi pi-check" className="p-button-raised" onClick={onSave}/>
        </div>
        {onCancel && <div className="p-field p-button-secondary p-button-raised ">
            <Button label={cancelText} icon="pi pi-times" className='p-button-raised p-button-secondary' onClick={onCancel}/>
        </div>}
        {children}
    </div>;
}
