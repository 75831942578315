import styled from 'styled-components';
import {RADIUS} from "../../../utils";

export const Nobbin = styled.div<{active?: boolean}>`
  position: absolute;
  height: ${RADIUS / 6}vmin;
  width: ${RADIUS / 6}vmin;
  border-radius: 100%;
  z-index: 10;
  background-image: linear-gradient(to bottom right, var(--surface-b) 0%, var(--primary-color) 50%, var(--surface-b)  100%);         
  transition: 0.1s;
  background-size: 300%;
  box-shadow: 0 0 10px #1d1d1d;
  background-position: ${props => props.active ? 'top left' : 'bottom right'};
    
  &:hover {
    ${props => props.active ? 'box-shadow: 0 0 20px #1d1d1d;' : ''};
    cursor: pointer;
  }            
  
  &:active {
    background-position: top left;
  }
  
  &.unspinnable {
    background: grey;
    &:hover {
      cursor: not-allowed;
    }      
  }
         
`;
