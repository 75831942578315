import React from 'react';
import {SpinnerPage} from "./modules/pages/SpinnerPage";
import {CategoriesPage} from "./modules/pages/CategoriesPage";
import {OptionsPage} from "./modules/pages/OptionsPage";
import {SettingsPage} from "./modules/pages/SettingsPage";

export enum RoutePaths {
    Root = '/',
    Categories = '/categories',
    Options = '/options',
    Settings = '/settings'
}

export const MainRoutes = {
    [RoutePaths.Root]: () => <SpinnerPage />,
    [RoutePaths.Categories]: () => <CategoriesPage />,
    [RoutePaths.Options]: () => <OptionsPage />,
    [RoutePaths.Settings]: () => <SettingsPage />,
};

