import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
// @ts-ignore
import {navigate} from "hookrouter";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {Menu, Menu as PMenu} from "primereact/components/menu/Menu";
import {SpinnerModelLabel, SpinnerModelsIcon, SpinnerModelsLabel, SpinnerModelType} from "../../../types/spinner";
import {RoutePaths} from "../../../routes";
import {CategoriesIcon, CategoriesLabel, CategoryLabel, CategoryType} from "../../../types/category";
import {OptionLabel, OptionsIcon, OptionsLabel, OptionType} from "../../../types/option";
import {FeedbackIcon, FeedbackLabel, openFeedback, SettingsIcon, SettingsLabel} from "../../../types/core";
import {useSafeCallback} from "../../../utils";

type Props = {
    showAddForm: (type: string) => void;
}

export function SmallScreenMenu({showAddForm, ...props}: Props) {
    const [open, setOpen] = useState(false);
    const goto = useCallback(target => {
        navigate(target);
        setOpen(false);
    }, [setOpen]);


    const mainItems = useMemo(() => ([
        {id: SpinnerModelType, label: SpinnerModelsLabel, icon: SpinnerModelsIcon, command: () => goto(RoutePaths.Root)},
        {id: CategoryType, label: CategoriesLabel, icon: CategoriesIcon, command: () => goto(RoutePaths.Categories)},
        {id: OptionType, label: OptionsLabel, icon: OptionsIcon, command: () => goto(RoutePaths.Options)},
        {id: 'settings', label: SettingsLabel, icon: SettingsIcon, command: () => goto(RoutePaths.Settings)},
        {
            id: 'feedback', label: FeedbackLabel, icon: FeedbackIcon, command: () => {
                openFeedback();
                setOpen(false);
            }
        }
    ].filter(x => !!x)), [goto]);

    const addItems = useMemo(() => ([
        // {id: SpinnerModelType, label: SpinnerModelLabel, icon: SpinnerModelsIcon, command: () => showAddForm(SpinnerModelType)},
        {id: CategoryType, label: CategoryLabel, icon: CategoriesIcon, command: () => showAddForm(CategoryType)},
        {id: OptionType, label: OptionLabel, icon: OptionsIcon, command: () => showAddForm(OptionType)}
    ]), [showAddForm]);

    const ref = useRef(null);
    const toggleAdd = useSafeCallback((e) => {
        setOpen(false);
        ref.current.toggle(e);
    }, [showAddForm, setOpen]);


    return <Container {...props}>
        <Button label="Menu" icon="pi pi-bars" onClick={() => setOpen(true)} className='p-button-text p-button-secondary'/>
        <Button tooltip='Add' icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-sm add-button" onClick={toggleAdd}/>
        <Menu model={addItems} popup ref={ref} className='centerMenu'/>
        <Sidebar visible={open} position='left' onHide={() => setOpen(false)} modal={false}>
            <MenuBar>
                <PMenu className="menu" model={mainItems}/>
            </MenuBar>
        </Sidebar>
    </Container>;
}


const MenuBar = styled.div`
  z-index: 99;
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;

  .menu {
    border: 0;
    width: 100%;
    margin-top: 0.3em;
  }
`;




