import React, {ReactNode, useMemo, useReducer} from 'react';
import {SpinnerSegment} from "../models";
import {SpinnerModel} from "../../../types/spinner";

export const SpinnerModelContext = React.createContext<SpinnerModelContext>(null);
export const DefaultSpinnerModelContext = React.createContext<SpinnerModel>(null);


type SetSpinnerModelAction = {
    type: "set";
    model: SpinnerModel;
}
type UpdateSpinnerModelSegment = {
    type: "updateSegment";
    i: number;
    segment: SpinnerSegment;
}

type SpinnerModelContextUpdate = SetSpinnerModelAction | UpdateSpinnerModelSegment;


type SpinnerModelContext = {
    model: SpinnerModel;
    actions: SpinnerModelActions;
}

type SpinnerModelActions = {
    set(model: SpinnerModel): void;
    updateSegment(i: number, segment: SpinnerSegment): void;
}

type SpinnerModelProviderProps = {
    children: ReactNode;
    model?: SpinnerModel;
}

export function SpinnerModelProvider({children, model}: SpinnerModelProviderProps) {
    const [_model, updateSpinnerModel] = useReducer((model: SpinnerModel, action: SpinnerModelContextUpdate) => {
        switch (action.type) {
            case "set": {
                return action.model;
            }
            case 'updateSegment': {
                const {i, segment} = action;
                const newModel = {...model};
                newModel.segments = [...model.segments];
                newModel.segments[i] = segment;
                return newModel;
            }
            default:
                return model;
        }
    }, model);
    const actions = useMemo(() => ({
        set: model => updateSpinnerModel({type: "set", model}),
        updateSegment: (i, segment) => updateSpinnerModel({type: "updateSegment", i, segment}),
    }), [updateSpinnerModel]);

    const context = useMemo(() => ({
        model: _model,
        actions
    }), [_model, actions]);


    return <SpinnerModelContext.Provider value={context}>
        {children}
    </SpinnerModelContext.Provider>
}
