import React, {useCallback, useContext, useState} from 'react';
import {BlockForm} from "../../../components/BlockForm";
import {DataActions} from "../localDB";
import {DataContext, DoObjectFormProps} from "../models";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {SaveOrCancel} from "../../../components/SaveOrCancel";
import {Button} from "primereact/button";
import {useSafeCallback} from "../../../utils";
import {createOption, Option} from "../../../types/option";

type OptionFormProps = DoObjectFormProps<Option>;

export function OptionForm({object: option, onSave, onDelete, onCancel}: OptionFormProps) {
    const {categoryArray, updateOptions} = useContext(DataContext);
    const [label, setLabel] = useState(option?.label || '');
    const [categories, setCategories] = useState(option?.categories || []);

    const updateOption = useSafeCallback((e) => {
        const object = createOption({
            ...(option || {}),
            id: option?.id,
            label,
            categories
        });
        updateOptions({
            type: DataActions.Save,
            object
        });
        onSave(object);
    }, [label, setLabel, option, categories, onSave]);

    const deleteOption = useCallback(() => {
        if (option) {
            updateOptions({
                type: DataActions.Remove,
                id: option.id
            });
        }
        onDelete();
    }, [option, updateOptions, onDelete]);

    const inputId = option?.id || '__add';
    return <BlockForm onSubmit={updateOption}>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label htmlFor={inputId} className="p-col-12 p-md-2">Label</label>
                <div className="p-col-12 p-md-10">
                    <InputText id={inputId} label='Label' autoFocus onChange={e => setLabel(e.currentTarget.value)} value={label}/>
                </div>
            </div>
            <div className="p-field p-grid">
                <label htmlFor={`${inputId}_select`} className="p-col-12 p-md-2">Categories</label>
                <div className="p-col-12 p-md-10">
                    <MultiSelect
                        id={`${inputId}_select`}
                        display="chip"
                        optionLabel="label"
                        optionValue='id'
                        value={categories}
                        options={categoryArray}
                        onChange={(e) => setCategories(e.value)}
                        filter
                    />
                </div>
            </div>
        </div>
        <SaveOrCancel onSave={updateOption} onCancel={onCancel}>
            {option && <div className="p-field">
              <Button label='Delete' icon="pi pi-times-circle" className='p-button-danger p-button-raised' onClick={deleteOption}/>
            </div>}
        </SaveOrCancel>
    </BlockForm>;
}
