import React, {ComponentType, useCallback, useEffect, useState} from "react";
import {DoObjectFormProps} from "../models";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Button} from "primereact/button";
import {DoObject} from "../../../types/core";

type Props<T extends DoObject> = {
    array: T[];
    Form: ComponentType<DoObjectFormProps<T>>;
}

export function DoObjectList<T extends DoObject>({array, Form}: Props<T>) {
    const [activeIndex, setActiveIndex] = useState<any>([]);
    const close = useCallback((i) => {
        setActiveIndex(activeIndex.filter(_i => _i !== i));
    }, [activeIndex, setActiveIndex]);

    useEffect(() => {
        if (array?.length === 0) {
            setActiveIndex([0]);
        }
    }, [array]);
    console.log('ARRAY', array);

    const [adding, setAdding] = useState(false);
    const stopAdding = useCallback(() => setAdding(false), [setAdding]);

    return <React.Fragment>
        <Accordion key='acc' multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {array?.map((object, i) => <AccordionTab key={object.id} header={object.label}>
                <Tab key={object.id} object={object} Form={Form} onClose={() => close(i)}/>
          </AccordionTab>)}
        </Accordion>
        <div className="p-mt-4 p-mb-4">
            {adding && <Form onSave={stopAdding} onCancel={stopAdding}/>}
            {!adding && <Button label='Add Category' onClick={() => setAdding(true)}/>}
        </div>
    </React.Fragment>;
}

type TabProps<T extends DoObject> = {
    object: T;
    onClose: () => void;
    Form: ComponentType<DoObjectFormProps<T>>;
}
function Tab<T extends DoObject>({object,  Form, onClose}: TabProps<T>) {
    console.log('OBJ', object);
    return <Form object={object} onSave={onClose} onCancel={onClose} onDelete={onClose}/>;
}
