import React, {ReactNode, useContext, useMemo, useState} from 'react';
import {DataContext} from "../data/models";
import {sortByLabel} from "../../utils";
import {SpinnerSegment} from "../spinner/models";
import {Spinner} from "../spinner/components/Spinner";
import styled from "styled-components";
import {Suggestions} from "../../components/Suggestions";
import {DefaultSpinnerModelContext, SpinnerModelContext, SpinnerModelProvider} from "../spinner/containers/SpinnerModelProvider";
import {DoObject} from "../../types/core";
import {Category} from "../../types/category";
import {createSpinnerModel} from "../../types/spinner";


export function SpinnerPage() {
    const {categories} = useContext(DataContext);
    const defaultModel = useMemo(() => {
        const sorted = sortByLabel<Category>(Object.values(categories));
        return createSpinnerModel({
            id: 'allCategories',
            label: 'All Categories',
            segments: sorted,
        });
    }, [categories]);

    return <DefaultSpinnerModelContext.Provider value={defaultModel}>
        <SpinnerModelProvider model={defaultModel}>
            <SpinnerContent/>
        </SpinnerModelProvider>
    </DefaultSpinnerModelContext.Provider>

}

function SpinnerContent() {
    const {model} = useContext(SpinnerModelContext);
    const [result, setResult] = useState<SpinnerSegment>(null);

    return <React.Fragment>
        <Spinner model={model} onResult={setResult}/>
        <Tray result={result?.object}>
            <Suggestions result={result?.object}/>
        </Tray>
    </React.Fragment>
}

type TrayProps = {
    result?: DoObject;
    children?: ReactNode;
}

const Tray = styled(({result, children, ...props}: TrayProps) => {
    return <div {...props}>
        <div className='wrapper'>
            <h3>{result?.label}</h3>
            {children}
        </div>
    </div>
})`
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  justify-content: start;

  .wrapper {
      margin: 1rem;
      text-align: center;
  }

  & button {
    margin: 0.6rem; 
  }
`;

