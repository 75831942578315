import colourConvert from 'color-convert';
import {DataActions} from "./modules/data/localDB";
import mapValues from 'lodash.mapvalues';

const _Colours = mapValues({
    1: ['red'],
    2: ['red', 'green'],
    3: ['red', 'green', 'blue'],
    4: ['red', 'yellow', 'green', 'blue'],
    5: ['red', 'yellow', 'green', 'blue', 'magenta'],
    6: ['red', 'orange', 'yellow', 'green', 'blue', 'magenta'],
    7: ['red', 'orange', 'yellow', 'green', 'cyan', 'blue', 'magenta'],
    8: ['red', 'orange', 'yellow', 'green', 'cyan', 'blue', 'indigo', 'magenta']
}, a => a.map(c => colourConvert.keyword.rgb(c)));


export const Colours = {
    get(n, i) {
        return _Colours[n]?.[i] || colourConvert.hsl.rgb([Math.floor(360/n) * i, 100, 50]);
    }
};

export type AnyObject = {[key: string]: any};

export type RemoveAction = {
    type: DataActions.Remove;
    id: string;
}

export type SaveAction<T> = {
    type: DataActions.Save;
    object: T
}

export type Action<T> = SaveAction<T> | RemoveAction;

export type Colour = [number, number, number, number?];
