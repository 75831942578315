import React, {useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from "react";
import classnames from 'classnames';
import {RADIUS, SPIN_TIME_S} from "../../../utils";
import styled from "styled-components";
import {Segments} from "./segments/Segments";
import {Pointer} from "./Pointer";
import {Nobbin} from "./Nobbin";
import {Choice, ModelContext, SpinContext, SpinnerSegment, SpinState} from "../models";
import {DefaultSpinnerModelContext, SpinnerModelContext} from "../containers/SpinnerModelProvider";
import {Button} from "primereact/button";
import {SpinnerModel} from "../../../types/spinner";

interface Props {
    model: SpinnerModel;

    onResult(segment: SpinnerSegment): void;
}

type ChoiceArgs = { type: 'reset' } | { type: 'set', i: number };

export function Spinner({model, onResult}: Props) {
    const defaultModel = useContext(DefaultSpinnerModelContext);
    const {actions} = useContext(SpinnerModelContext);
    const [spinning, setSpinning] = useState(false);
    const {segments} = model;
    const enabledSegments = segments.filter(s => !s.disabled);
    const unspinnable = enabledSegments.length === 0;

    const [choice, setChoice] = useReducer((previous, action: ChoiceArgs): Choice => {
        let origin = previous.degrees;
        if (previous.degrees % 360) {
            origin = previous.degrees + (360 - previous.degrees % 360);
        }
        switch (action.type) {
            case 'reset': {
                return {degrees: origin, i: null};
            }
            case 'set': {
                const offset = (model.deg * action.i) + (Math.random() * model.deg);
                const padding = 360 + Math.floor(Math.random() * 20) * 360;

                console.log('Chosen', action.i, offset, origin, padding);
                return {
                    degrees: origin + padding + offset,
                    i: action.i
                }
            }
            default:
                return previous;
        }

    }, {degrees: 0, i: null});

    const spinState: SpinState = useMemo(() => ({
        spinning,
        choice
    }), [spinning, choice]);

    const ref = useRef<any>();
    const spin = useCallback(() => {
        if (unspinnable) {
            return;
        }

        setSpinning(true);
        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
            setSpinning(false);
        }, (SPIN_TIME_S) * 1000);

        const chosen = enabledSegments[Math.floor(Math.random() * enabledSegments.length)];
        setChoice({type: 'set', i: chosen.i});
    }, [setSpinning, ref, unspinnable, enabledSegments]);


    const resettable = defaultModel !== model || choice?.i !== null;
    const reset = useCallback(() => {
        clearTimeout(ref.current);
        setSpinning(false);
        actions.set(defaultModel);
        setChoice({type: 'reset'});
    }, [defaultModel, actions]);

    useEffect(() => {
        const result = !spinning && model?.segments?.[choice?.i];
        onResult(result);
    }, [choice, model, spinning, onResult]);

    useEffect(() => {
        if (model) {
            setChoice({type: 'reset'});
        }
    }, [model, setChoice]);

    return <ModelContext.Provider value={model}>
        <SpinContext.Provider value={spinState}>
            {resettable && <ResetButton onClick={reset} className='p-button-secondary reset'>Reset</ResetButton>}
            <Board>
                <Segments/>
                <Pointer/>
                <Nobbin onClick={spin} className={classnames(unspinnable && 'unspinnable')}/>
            </Board>
        </SpinContext.Provider>
    </ModelContext.Provider>
}

const ResetButton = styled(Button)`
  position: absolute;
  top: 4rem;
  left: 1rem;  
  
  @media (min-width: 720px) {
      top: 5rem;
      left: 2rem;  
  }
`;


const Board = styled.div`
  position: relative;
  width: ${RADIUS * 2}vmin;
  height: ${RADIUS * 2}vmin;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12vh;
  margin-bottom: 4vh;

  @media (min-aspect-ratio: 8/9) {
    margin-top: 4vh;
  }
   
  
  @media (var(--)) {
    margin-top: 4vh;
  }
`;
