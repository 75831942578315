import styled from 'styled-components';
import React, {useContext} from "react"
import {RADIUS, SPIN_TIME_S} from "../../../utils";
import {SpinContext} from "../models";

const SIZE = RADIUS;

export function Pointer(props) {
    const spinState = useContext(SpinContext);
    return <PointerContainer {...props} degrees={spinState?.choice?.degrees}>
            <PointerSVG height="100%" width="100%" viewBox="0 0 100 100">
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style={{stopColor:'rgb(233,255,255)',stopOpacity:1}} />
                        <stop offset="30%" style={{stopColor:'rgb(240,240,245)',stopOpacity:1}} />
                        <stop offset="75%" style={{stopColor:'rgb(188,188,188)',stopOpacity:1}} />
                        <stop offset="85%" style={{stopColor:'rgb(222,222,222)',stopOpacity:1}} />
                        <stop offset="100%" style={{stopColor:'rgb(233,233,233)',stopOpacity:1}} />
                    </linearGradient>
                </defs>
                <polygon points="50,-140 100,52 50,145 0,52" fill="url('#grad1')"></polygon>
            </PointerSVG>
    </PointerContainer>;
}

const PointerContainer = styled.div<{degrees: number}>`
  position: absolute;
  width: ${SIZE/4}vmin;
  height: ${SIZE}vmin;
  transform-origin: 50% 50%;
  transform: rotate(${({degrees=0}) => degrees}deg);
  transition: ${SPIN_TIME_S}s;
  z-index: 8;
`;

const PointerSVG = styled.svg`
  filter: drop-shadow(0 0 30px #333);
`;
