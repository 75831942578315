import React from 'react';
import {Page} from "../../components/Page";
import {Fieldset} from "primereact/fieldset";
import {ThemeSwitcher} from "../theme/components/ThemeSwitcher";
import {DataReset} from "../data/containers/DataReset";


export function SettingsPage() {
    return <Page>
        <h2>Settings</h2>
        <Fieldset legend="Theme">
            <ThemeSwitcher/>
        </Fieldset>
        <Fieldset legend="Data">
            <DataReset/>
        </Fieldset>
    </Page>;
}
