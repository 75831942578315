import {useContext, useMemo} from "react";
import {DataContext} from "./models";
import {DoObject} from "../../types/core";
import {CategoryType} from "../../types/category";


export function useChosenOptions(object: DoObject) {
    const {optionsArray} = useContext(DataContext);
    const chosenCategoryId = (object?.type === CategoryType) && object?.id;

    return useMemo(() => {
        return chosenCategoryId && optionsArray?.filter(({categories}) => categories.includes(chosenCategoryId));
    }, [optionsArray, chosenCategoryId]);
}
