import React, {useContext, useMemo} from 'react';
import styled from 'styled-components';
import {RADIUS} from "../../../../utils";
import {ModelContext, SpinContext, SpinnerSegment} from "../../models";
import {SegmentContent} from "./SegmentContent";
import {Wheel} from "./Wheel";


export function Segments(props) {
    const model = useContext(ModelContext);
    const {spinning, choice} = useContext(SpinContext);

    const chosenSegment = useMemo(() => {
        return !spinning && model?.segments?.[choice?.i];
    }, [spinning, choice, model]);

    const segments: SpinnerSegment[] = model?.segments?.map((segment, i) => ({
        ...segment,
        i,
        selected: segment === chosenSegment
    })) || [];

    return <SegmentsContainer {...props}>
        <Wheel segments={segments} />
        {segments.map(segment => <SegmentContent key={`cnt${segment.i}`} segment={segment} />)}
    </SegmentsContainer>;
}

const SegmentsContainer = styled.div`
  width: ${RADIUS * 2}vmin;
  height: ${RADIUS * 2}vmin;      
`;




