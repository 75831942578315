import {createOption, Option} from "../../types/option";
import Case from "case";
import mapValues from 'lodash.mapvalues';
import {Category, createCategory} from "../../types/category";
import {arrayToObject} from "../../utils";
import {CategoryDB, OptionDB} from "./dbs";

export async function initialise() {
    let categories: Category[] = await CategoryDB.load();
    let options: Option[] = await OptionDB.load();

    if (!categories) {
        categories = OriginalCategories;
        await CategoryDB.saveArray(categories);
    }
    if (!options) {
        options = OriginalOptions;
        await OptionDB.saveArray(options);
    }

    return {
        categories: arrayToObject(categories, 'id'),
        options: arrayToObject(options, 'id'),
    };
}

const OriginalCategories: Category[] = ['Study', 'Maintenance', 'Create', 'Exercise', 'Contemplate', 'Work', 'Socialise', 'Chill', 'Relationship', 'Self-care']
    .map(label => createCategory({
        id: Case.snake(label),
        label
    }));
const OriginalOptions: Option[] = Object.values(mapValues({
    'Read a book': ['Study', 'Chill', 'Contemplate'],
    'Read a magazine': ['Study', 'Chill', 'Contemplate'],
    'Listen to a podcast': ['Chill', 'Study', 'Contemplate'],
    'Tidy the house': ['Maintenance', 'Work', 'Exercise', 'Relationship', 'Self-care'],
    'Organise tools': ['Maintenance'],
    'Paint': ['Create', 'Chill', 'Contemplate', 'Self-care'],
    'Draw': ['Create', 'Chill', 'Contemplate'],
    'Pottery': ['Create'],
    'Cook a meal': ['Create', 'Chill', 'Maintenance'],
    'Cardio': ['Exercise', 'Relationship'],
    'Yoga/Pilates': ['Exercise', 'Contemplate', 'Self-care'],
    'Go for a walk': ['Exercise', 'Contemplate', 'Chill', 'Relationship', 'Self-care'],
    'Meditate': ['Contemplate', 'Chill', 'Self-care'],
    'Listen to music': ['Contemplate', 'Chill', 'Self-care'],
    'Dance': ['Exercise', 'Contemplate', 'Chill', 'Relationship', 'Self-care'],
    'Have a bath': ['Contemplate', 'Chill', 'Self-care'],
    'Paid work': ['Work'],
    'Side-hustle': ['Work', 'Create'],
    'Work on Essay': ['Work', 'Study'],
    'Call someone': ['Socialise', 'Chill', 'Relationship', 'Self-care'],
    'Arrange a meetup': ['Socialise'],
    'Watch netflix': ['Chill', 'Relationship'],
    'Clean car/motorbike': ['Maintenance'],
    'Laundry': ['Maintenance', 'Relationship', 'Self-care'],
    'Gardening': ['Maintenance', 'Exercise', 'Contemplate', 'Chill'],
    'Admin tasks': ['Maintenance', 'Work', 'Study', 'Self-care'],
    'Make music': ['Chill', 'Create'],
    'Play piano': ['Chill', 'Create'],
    'DIY': ['Maintenance', 'Create'],
    'Intimacy': ['Chill', 'Relationship', 'Socialise'],
    'Sort attic/garage': ['Maintenance', 'Exercise'],
    'Tidy study': ['Maintenance', 'Self-care'],
    'Skip-run': ['Maintenance', 'Exercise']
}, (categories, label) => createOption({
    id: Case.snake(label),
    label,
    categories: categories.map(c => Case.snake(c))
})));
