import {ThemeSwitcherProvider, useThemeSwitcher} from "react-css-theme-switcher";
import React from "react";
import {Dropdown} from "primereact/dropdown";
import Case from 'case';
import {Themes} from "../constants";
import {LS} from "../../../localstorage";


const themeMap = Themes.reduce((map, theme) => ({...map, [theme]: `/themes/${theme}/theme.css`}), {});
const themeOptions = Themes.map(value => ({label: Case.title(value), value}));


export function ThemeProvider({children}) {
    const defaultTheme = LS.getItem('theme') || "arya-blue";
    return <ThemeSwitcherProvider defaultTheme={defaultTheme} themeMap={themeMap}>
        <ThemeLoader>
            {children}
        </ThemeLoader>
    </ThemeSwitcherProvider>
}

function ThemeLoader({children}) {
    const {status} = useThemeSwitcher();
    if (status === 'loading') {
        return <React.Fragment/>;
    }
    return children;
}

export function ThemeSwitcher() {
    const {switcher, themes, currentTheme, status} = useThemeSwitcher();
    if (status === 'loading') {
        return <React.Fragment />;
    }
    const onChange = (e) => {
        LS.setItem('theme', e.value);
        switcher({theme: themes[e.value]});
    };

    return <Dropdown value={currentTheme} options={themeOptions} onChange={onChange} placeholder="Select a City"/>
}
