import React, {useMemo, useRef} from 'react';
import styled from "styled-components";
// @ts-ignore
import {navigate} from "hookrouter";
import {Menu, Menu as PMenu} from "primereact/components/menu/Menu";
import {Button} from "primereact/button";
import {CategoriesIcon, CategoriesLabel, CategoryLabel, CategoryType} from "../../../types/category";
import {OptionLabel, OptionsIcon, OptionsLabel, OptionType} from "../../../types/option";
import {SpinnerModelsIcon, SpinnerModelsLabel, SpinnerModelType} from "../../../types/spinner";
import {RoutePaths} from "../../../routes";
import {FeedbackIcon, FeedbackLabel, openFeedback, SettingsIcon, SettingsLabel} from "../../../types/core";

type Props = {
    showAddForm: (type: string) => void;
}

export function ToolbarMenu({showAddForm, ...props}: Props) {
    const left = useMemo(() => ([
        {id: SpinnerModelType, label: SpinnerModelsLabel, icon: SpinnerModelsIcon, command: () => navigate(RoutePaths.Root)},
        {id: CategoryType, label: CategoriesLabel, icon: CategoriesIcon, command: () => navigate(RoutePaths.Categories)},
        {id: OptionType, label: OptionsLabel, icon: OptionsIcon, command: () => navigate(RoutePaths.Options)},
    ]), []);

    const center = useMemo(() => ([
        // {id: SpinnerModelType, label: SpinnerModelLabel, icon: SpinnerModelsIcon, command: () => showAddForm(SpinnerModelType)},
        {id: CategoryType, label: CategoryLabel, icon: CategoriesIcon, command: () => showAddForm(CategoryType)},
        {id: OptionType, label: OptionLabel, icon: OptionsIcon, command: () => showAddForm(OptionType)}
    ]), [showAddForm]);

    const right = useMemo(() => ([
        {id: 'feedback', label: FeedbackLabel, icon: FeedbackIcon, command: openFeedback},
        {id: 'settings', label: SettingsLabel, icon: SettingsIcon, command: () => navigate(RoutePaths.Settings)}
    ]), []);

    const ref = useRef(null);

    return <Container {...props}>
        <LeftBlock>
            <PMenu className="menu left-menu" model={left}/>
        </LeftBlock>
        <CenterBlock>
            <Button tooltip='Add' icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-lg add-button" onClick={e => ref.current.toggle(e)}/>
            <Menu model={center} popup ref={ref} className='centerMenu'/>
        </CenterBlock>
        <RightBlock>
            <PMenu className="menu right-menu" model={right}/>
        </RightBlock>
    </Container>
}

const LeftBlock = styled.div`
  display: flex;
  flex: 1 1 50%;  
`;
const CenterBlock = styled.div`
  flex: 0 0 auto;
  
  .add-button {
    margin-top: 0.33rem;
  }
  .centerMenu {
    margin-top: 0.33em;
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;   
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;  
  border: 0;  
  background: var(--surface-b);   
  border-bottom: 1px solid var(--surface-c);
  
  .menu { 
    border: none;
    background: transparent;
    width: auto;
    
    ul {
      display: flex;
      flex-direction: row;    
    }
  }   
`;
