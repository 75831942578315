import 'regenerator-runtime/runtime'
import 'normalize.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './style.scss';
import PrimeReact from 'primereact/utils';
import React from "react"
import ReactDOM from "react-dom"
import styled from 'styled-components';
import {DataContainer} from "./modules/data/containers/DataContainer";
import {Menu} from "./modules/nav/components/Menu";
// @ts-ignore
import {useRoutes} from "hookrouter";
import {MainRoutes} from "./routes";
import {SpinnerPage} from "./modules/pages/SpinnerPage";
import {ThemeProvider} from "./modules/theme/components/ThemeSwitcher";


PrimeReact.ripple = true;


// localStorage.clear();


function App() {
    const component = useRoutes(MainRoutes);
    return <ThemeProvider>
        <Wrapper>
            <DataContainer>
                <AppBar>
                    <Menu/>
                </AppBar>
                {component || <SpinnerPage/>}
            </DataContainer>
        </Wrapper>
    </ThemeProvider>
    ;
}


const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
`;


const AppBar = styled.div`
   flex: 0 0 auto;
   display: flex;
   width: 100%;
   z-index: 20;
`;


ReactDOM.render(<App/>, document.getElementById("root"));
