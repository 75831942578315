import React from 'react';
import {Action} from "../../models";
import {DoObject} from "../../types/core";
import {Category} from "../../types/category";
import {Option} from "../../types/option";


export type DataState = {
    categories: {[id: string]: Category};
    categoryArray: Category[];
    options: {[id: string]: Option};
    optionsArray: Option[];
    updateCategories: (x: Action<Category>) => void;
    updateOptions: (x: Action<Option>) => void;
    reset: () => void;
}

export const DataContext = React.createContext<DataState>(null);


export type DoObjectFormProps<T extends DoObject> = {
    object?: T;
    onSave: (object: T) => void;
    onCancel: () => void;
    onDelete?: () => void;
}
export type DoObjectForm<T extends DoObject> = React.ComponentType<DoObjectFormProps<T>>;

