import React, {useCallback, useContext} from 'react';
import {useChosenOptions} from "../modules/data/hooks";
import {Button} from "primereact/button";
import {SpinnerModelContext} from "../modules/spinner/containers/SpinnerModelProvider";
import {DoObject} from "../types/core";
import {createSpinnerModel} from "../types/spinner";

type Props = {
    result?: DoObject;
}

export function Suggestions({result}: Props) {
    const options = useChosenOptions(result);
    const {actions} = useContext(SpinnerModelContext);

    const spinThese = useCallback(() => {
        const spinnerModel = createSpinnerModel({
            id: `${result?.id}_options`,
            label: `${result?.label} Options`,
            segments: options
        });
        actions.set(spinnerModel);
    }, [options, actions, result?.id, result?.label]);

    if (!result) {
        return <React.Fragment/>
    }
    return <React.Fragment>
        {Array.isArray(options) && !!options.length && <p>e.g. {options?.map(({label}) => label).join(', ')}</p>}
        {Array.isArray(options) && <Button onClick={spinThese}>Spin on these</Button>}
    </React.Fragment>;
}
